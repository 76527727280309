<template>
  <div class="preview-actionType">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title" v-html="formData.description"></h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle" />
    </div>

    <CTabs class="zq--tabs">
      <CTab title="Api Key">
        <ApiKeyDetails></ApiKeyDetails>
      </CTab>
    </CTabs>
<!--    <Modal-->
<!--      :modalShow="isApiKeyAvailable"-->
<!--      :messageGeneral="texts.previewPage.modalMessageGeneral"-->
<!--      :title="texts.previewPage.modalTitle"-->
<!--      :dangerText="externalReference"-->
<!--      :isCloseButton="false"-->
<!--      :successBtnLabel="texts.previewPage.modalSuccessBtn"-->
<!--      @doFunction="closeModal"-->
<!--      v-on:toggle-modal="closeModal"-->
<!--    ></Modal>-->
  </div>
</template>

<script>
import ApiKeyDetails from '@/generated/ziqni/views/apiKeys/ApiKeyDetails';
import { mapGetters } from "vuex";

import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import Modal from '@/shared/UI/Modal';

import { apiKeys } from "@/config/descriptions/apiKeys"
import { apiKeysTexts } from "@/config/pageTexts/apiKeys.json";

export default {
  components: {
    ApiKeyDetails,
    IconWithTooltip,
    Modal,
  },
  props: {
    externalReference: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      texts: {
        ...apiKeysTexts
      },
      descriptions: {
        ...apiKeys.preview
      },
      formData: {
        id: '',
      },
      // isApiKeyAvailable: false,
    };
  },
  computed: {
    ...mapGetters('apiKeys', ['apiKey']),
  },
  watch: {
    apiKey: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
  mounted() {
    // if (this.externalReference) {
    //   this.isApiKeyAvailable = true;
    // }
  },
  methods: {
    // closeModal() {
    //   this.isApiKeyAvailable = false;
    // }
  },
}
</script>

<style lang="scss">
.preview-actionType {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
